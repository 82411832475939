import React, { useContext, useEffect } from 'react'
import { Field, Formik, useFormikContext } from 'formik'
import { Button, Spacer, theme, window } from '@mtsbank/ui-kit'
import { PhoneInputField } from '@components/FormFields/PhoneInputField'
import { configLoginForm } from '@components/TspPayee/config/configLoginForm'
import { AuthFormValues } from '@components/TspPayee/withoutAuthorization/type'
import { validationRules } from '@utils/formValidators/rules'
import { PaymentFormDataContext } from '@components/TspPayee/TspPayee'
import { correctPhoneOnAuthTabGtm, submitOnAuthTabGtm } from '@utils/gtm/serviceIdPayment/events'
import { statDataLoader } from '@utils/gtm/StatDataLoader'
import { EventType } from '@utils/gtm/baseFunctions'

const { spacings } = theme
const { loginPhoneNumberField } = configLoginForm

interface Props {
  serviceId: string
  name: string
}

const FormikHandlerLoginFormFields = ({ serviceId, name }) => {
  const {
    values: { loginPhoneNumber },
    submitForm,
  } = useFormikContext<AuthFormValues>()

  const disabled = loginPhoneNumber?.length !== validationRules.minPhoneNumber || false

  useEffect(() => {
    if (!disabled) {
      statDataLoader.push((event: EventType) => correctPhoneOnAuthTabGtm(event, serviceId as string, name as string))
    }
  }, [disabled, serviceId, name])

  return (
    <>
      <Spacer space={spacings.sm} spacemob={spacings.md} />
      <Field {...loginPhoneNumberField} component={PhoneInputField} />
      <Spacer space={spacings.md} spacemob={spacings.lg} />
      <Button fluidmob size="lg" sizemob="lg" onClick={submitForm} disabled={disabled}>
        Далее
      </Button>
    </>
  )
}

export const FormikHandlerLoginForm = ({ serviceId, name }: Props) => {
  const context = useContext(PaymentFormDataContext)
  const handleClick = () => {
    statDataLoader.push((event: EventType) => submitOnAuthTabGtm(event, serviceId, name))
    const formatPhoneNum = context.values.loginPhoneNumber.slice(2).replace(/[^\d]/g, '')

    const redirectTo: string = window.location.href

    localStorage.setItem('sso_returnUrl', redirectTo)

    window.location.href = `/auth/signIn?phone=${formatPhoneNum}`
  }

  return (
    <Formik
      validateOnChange={false}
      onSubmit={handleClick}
      validate={(e) => context.updateValues(e)}
      initialValues={{
        ...configLoginForm.initialValues,
        ...context.values,
      }}
      validationSchema={configLoginForm.validationSchema}
    >
      <FormikHandlerLoginFormFields serviceId={serviceId} name={name} />
    </Formik>
  )
}

import { useState } from 'react'
import { ewalletApi } from '@root/api/ump/ewallet'
import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { ServiceParams, TransactionResponsRs } from '@open-api/ump/ewallet'

interface PaymentDoReqProps {
  zone: 'b' | 'anonymous'
  serviceId?: string
  amount?: number
  currency?: number
  bindingId?: string
  pan?: string
  mdOrder?: string
  expiry?: string
  cardholderName?: string
  cvc?: number
  serviceParams?: ServiceParams
  comment?: string
  masterpassOperationId?: string
  is3dsRequired?: boolean
  options?: any
  onSuccess?: (data: TransactionResponsRs) => void
  onError?: (error) => void
  isManualLoadingControl?: boolean
}

export const usePaymentDo = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { userProfile } = authHelper
  const userId = userProfile?.userId

  const toggleLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const paymentDo = async ({
    zone,
    currency,
    mdOrder,
    amount,
    serviceId,
    bindingId,
    pan,
    expiry,
    cardholderName,
    cvc,
    serviceParams,
    comment,
    masterpassOperationId,
    is3dsRequired,
    options,
    onSuccess,
    onError,
    isManualLoadingControl,
  }: PaymentDoReqProps) => {
    setIsLoading(true)

    try {
      const { data } = await ewalletApi.payment(
        zone,
        userId,
        mdOrder,
        bindingId,
        pan,
        expiry,
        cardholderName,
        cvc,
        serviceId,
        serviceParams,
        amount,
        currency,
        comment,
        masterpassOperationId,
        is3dsRequired,
        options
      )

      if (!data.errorMessage) {
        if (onSuccess) onSuccess(data)
      } else if (onError) {
        onError(new Error(data.errorMessage))
      }
    } catch (error) {
      if (onError && error?.code !== 'ERR_CANCELED') onError(error)
    } finally {
      if (!isManualLoadingControl) {
        setIsLoading(false)
      }
    }
  }

  return { paymentDo, isLoading, toggleLoading }
}

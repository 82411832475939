import React, { FC } from 'react';
import { Field } from 'formik';
import { FieldProps } from '@components/TspPayee/withoutAuthorization/type';
import { BalanceInquiryWrapper, BalanceButton } from './styled';
interface Props {
  field: FieldProps;
  isLoading: boolean;
  onClick: () => void;
}
export const BalanceInquiryForm: FC<Props> = ({
  field,
  isLoading,
  onClick
}) => <BalanceInquiryWrapper>
    <Field {...field} size="lg" />
    <BalanceButton size="lg" variant="outline" isLoading={isLoading} onClick={onClick}>
      Проверить баланс
    </BalanceButton>
  </BalanceInquiryWrapper>;
import { isEmpty, omitBy } from 'lodash'
import { TextField } from '@mtsbank/ui-kit'
import { CheckBoxField } from '@components/FormFields/CheckboxField'
import { PasswordField } from '@components/FormFields/PasswordField'
import { SelectField } from '@root/components/TspPayee/formFields/SelectField'
import { PhoneField } from '@root/components/TspPayee/formFields/PhoneField'
import { InputMaskTextField } from '@root/components/TspPayee/formFields/InputMaskTextField'
import { Param } from '@open-api/ump/catalog-manager'
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber'
import { ServiceParams } from '../types'
import { WithoutAuthFormValues } from '../withoutAuthorization/type'
import { trimString } from './helpers'
import { BINS_WITH_NOT_COMMISSION } from './constants'

export enum ServiceParamNameKeys {
  Phone,
  PayerPhone,
  FiscalEmail,
  OrderNumber,
  Uniq,
  PaymentType,
  AutoPayId,
  BillRegId,
}

export enum ParameterTypeEnum {
  Unknown = 'Unknown',
  StringField = 'StringField',
  NumberField = 'NumberField',
  EmailField = 'EmailField',
  DateField = 'DateField',
  DateMonthField = 'DateMonthField',
  DateTimeField = 'DateTimeField',
  CheckBox = 'CheckBox',
  PhoneField = 'PhoneField',
  PasswordField = 'PasswordField',
  ComboBox = 'ComboBox',
  Amount = 'Amount',
  PanField = 'PanField',
}

export const serviceParamTypeToComponent = {
  [ParameterTypeEnum.Unknown]: InputMaskTextField,
  [ParameterTypeEnum.StringField]: InputMaskTextField,
  [ParameterTypeEnum.NumberField]: InputMaskTextField,
  [ParameterTypeEnum.EmailField]: InputMaskTextField,
  [ParameterTypeEnum.DateField]: InputMaskTextField,
  [ParameterTypeEnum.DateMonthField]: InputMaskTextField,
  [ParameterTypeEnum.DateTimeField]: InputMaskTextField,
  [ParameterTypeEnum.CheckBox]: CheckBoxField,
  [ParameterTypeEnum.PhoneField]: PhoneField,
  [ParameterTypeEnum.PasswordField]: PasswordField,
  [ParameterTypeEnum.ComboBox]: SelectField,
  [ParameterTypeEnum.Amount]: TextField,
  [ParameterTypeEnum.PanField]: TextField,
  // [ParameterTypeEnum.HybridField] : ,
}

export const collectServiceParams = (
  inputParams: Param[],
  data: Partial<WithoutAuthFormValues>,
  opts = { onlyRequired: false }
): ServiceParams =>
  omitBy(
    inputParams.reduce((result, item) => {
      if ((data[item.name] && !opts.onlyRequired) || (opts.onlyRequired && item.required)) {
        if (item.type === ParameterTypeEnum.PhoneField) {
          result[item.name] = formatPhoneNumber(String(data[item.name] || item.defaultValue), FormatAliasPhone.DIGIT10)
        } else if (item.regExp) {
          result[item.name] = trimString(String(data[item.name]), item.mask?.includes(' ')) || item.defaultValue
        } else {
          result[item.name] = String(data[item.name] || item.defaultValue)
        }
      }

      return result
    }, {}),
    isEmpty
  )

export const binsWithNotCommissionForMTS = BINS_WITH_NOT_COMMISSION.reduce<Record<string, string>>((acc, el) => {
  acc[el] = el

  return acc
}, {})

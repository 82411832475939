import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { Dictionary } from '@reduxjs/toolkit';
import omitBy from 'lodash/omitBy';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { debounce, get, set } from 'lodash';
import { Styled as InputControlStyled } from '@mtsbank/ui-kit/build/components/atoms/InputControl/styled';
import { useToast } from '@mtsbank/ui-kit';
import { Binding, FieldProps } from '@components/TspPayee/withoutAuthorization/type';
import { serviceParamTypeToComponent } from '@components/TspPayee/utils/serviceUtils';
import { ExtendedServiceParameter } from '@components/TspPayee/types';
import { PaymentFormFields } from '@root/components/TspPayee/PaymentFormFields';
import { configPaymentWithoutAuth } from '@components/TspPayee/config/configPaymentWithoutAuth';
import { Param as PayeeParameter, Payee } from '@open-api/ump/catalog-manager';
import { EWCharge } from '@open-api/ump/charge-invoice';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { TransactionResponsRs } from '@open-api/ump/ewallet';
import { route } from '@root/utils/route/route';
import { paymentHost } from '@root/api/dot-net/api';
import { SPA_PAY_URL_RESULT } from '@root/constants';
import { phoneRegExp } from '@root/constants/regExp';
import { show3dsGtm } from '@root/utils/gtm/tsp/events';
import { SberPayContext } from '@root/pages/perevod_v_sber';
import { usePaymentDo } from '@root/hooks/usePaymentDo';
import { getResultPath } from '../utils/getResultPath';
import { getConfirmationContent } from '../utils/getConfirmationContent';
import { trimString } from '../utils/helpers';
import { ERROR_PHONE } from '../constants';
const {
  validationSchema
} = configPaymentWithoutAuth;
interface Props {
  serviceInfo: Payee;
  // Ожидается при оплате выбранного счета
  charge?: EWCharge;
  setMdOrder: (mdOrder: string) => void;
  setIsOtp: (isShowOtp: boolean) => void;
  setPayeeData?: (payeeData: Payee) => void;
}
export const PaymentForm: FC<Props> = ({
  serviceInfo,
  charge,
  setMdOrder,
  setIsOtp,
  setPayeeData
}) => {
  const router = useRouter();
  const routerQueryId = router?.query.id;
  const isWebView = (router?.query?.isWebView as string);
  const hasWebView = isWebView === 'true';
  const sberPayContext = useContext(SberPayContext);
  const {
    toast
  } = useToast();
  const formikRef = useRef(null);
  const [isCommissionMTSPay, setIsCommissionMTSPay] = useState(false);
  const [termsData, setTermsData] = useState<Binding>(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const {
    paymentDo,
    isLoading: isPaymentDoLoading,
    toggleLoading: togglePaymentDoLoading
  } = usePaymentDo();
  const isAmountReadonly = useMemo(() => {
    if (charge?.paymentDetails?.amount) {
      return true;
    }
    return false;
  }, [charge]);
  const {
    params: serviceParameters,
    invoiceSearchParams: invoiceParameters,
    balanceInquiryEnabled,
    limits
  } = serviceInfo || {};
  const defaultParameters: PayeeParameter[] = (balanceInquiryEnabled ? invoiceParameters : serviceParameters) || [];
  const {
    initialValues
  } = configPaymentWithoutAuth;
  const redirectToResult = (mdOrder: string) => {
    const generatedPath = route.generatePath(SPA_PAY_URL_RESULT, {
      mdOrder
    });
    const path = `${paymentHost}/${generatedPath}`;
    if (sberPayContext?.isAuthMTS) {
      router.push(hasWebView ? `${path}?token_id=${sberPayContext?.token_mts}&isWebView=true` : `${path}?token_id=${sberPayContext?.token_mts}`);
    } else if (hasWebView) {
      router.push(`${path}?isWebView=true`);
    } else {
      router.push(path);
    }
  };
  const handleCallOtp = (mdOrder: string) => {
    setMdOrder(mdOrder);
    setIsOtp(true);
  };
  const handleCall3DS = () => {
    show3dsGtm(serviceInfo.serviceId);
  };
  const handleCall3DS2 = (data: TransactionResponsRs) => {
    show3dsGtm(serviceInfo.serviceId);
    redirectToResult(data?.mdOrder);
  };
  const handleCallError = (errorMessage?: string) => {
    togglePaymentDoLoading(false);
    toast('error', 'Ошибка платежа', errorMessage || '', {
      withClose: true,
      withTimeout: true,
      timeout: 3000
    });
  };

  // Отправка формы при нажатии кнопки "Оплатить"
  const handleSubmit = () => {
    const zone = getPcekZone(sberPayContext?.isAuthMTS);
    if (termsData) {
      const {
        mdOrder
      } = termsData;
      let options = sberPayContext?.headers;
      if (zone === 'anonymous') {
        options = {
          headers: {
            'client-Id': 'mts-money-web-mtsid',
            FhpSessionId: window.gibSessionId,
            FhpRequestId: window.gibRequestId
          }
        };
      }
      paymentDo({
        zone,
        mdOrder,
        options,
        isManualLoadingControl: true,
        onSuccess: data => {
          setIsPaymentSuccess(true);
          if (data.state === 2 || data.state === 7) {
            redirectToResult(data?.mdOrder);
          } else if (data.state === 5) {
            getConfirmationContent({
              data,
              getResultPath: () => getResultPath(data?.mdOrder, hasWebView ? `?tsp=${routerQueryId}&isWebView=true` : `?tsp=${routerQueryId}`),
              handleCallOtp,
              handleCall3DS,
              handleCall3DS2,
              handleError: handleCallError,
              sberPayContext
            });
          }
        },
        onError: error => {
          togglePaymentDoLoading(false);
          toast('error', (error?.message as string), '', {
            withClose: true,
            withTimeout: true,
            timeout: 3000
          });
        }
      });
    }
  };

  // инициализирует поля fields для формы, задавая им компонент для отрисовки и подменяя type на mappedType
  const fields = useMemo(() => defaultParameters.filter(param => !param.hidden).sort((param: ExtendedServiceParameter) => param.displayOrder).map((param: ExtendedServiceParameter) => {
    const statFunction = noop;
    return ({
      enumId: param.enumId,
      name: param.name,
      label: param.title,
      placeholder: param.description,
      component: serviceParamTypeToComponent[param.type],
      isHidden: param.hidden,
      isClearable: false,
      readOnly: param.readOnly,
      autoComplete: 'off',
      serviceId: serviceInfo?.serviceId,
      ...(param.userHint ? {
        controlChildren: <InputControlStyled.CustomToolTip hint={param.userHint} onMouseOver={statFunction} onClick={statFunction} />
      } : {}),
      ...(param.mask || param.regExp ? {
        mask: omitBy({
          regex: param.regExp,
          mask: param.mask
        }, isEmpty)
      } : {})
    } as FieldProps);
  }, ([] as FieldProps[])), [defaultParameters]);

  // eslint-disable-next-line no-unsafe-optional-chaining
  const amount = useMemo(() => {
    if (!charge) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    const {
      amountWithDiscount,
      amount
    } = charge?.paymentDetails;
    return +(amountWithDiscount || amount.base) / 100 || initialValues.amount;
  }, [charge, initialValues]);
  const initialValuesWithParams = useMemo(() => ({
    ...initialValues,
    ...defaultParameters.reduce((preparedParams: Dictionary<string>, param: ExtendedServiceParameter) => ({
      ...preparedParams,
      [param.name]: charge?.paymentDetails[param.name] || initialValues[param.name] || param.defaultValue,
      amount
    }), {})
  }), [amount, charge, defaultParameters, initialValues]);
  const amountRules = useMemo(() => {
    let rules = Yup.number().transform((value, prev) => prev ? parseFloat(`0${prev}`.replace(',', '.')) : undefined).required('Обязательное поле');
    if (limits?.max) {
      const maxLimit = limits.max / 100;
      rules = rules.max(maxLimit, `Максимальная сумма платежа ${maxLimit} ₽`);
    }
    if (limits?.min) {
      const minLimit = limits.min / 100;
      rules = rules.min(minLimit, `Минимальная сумма платежа ${minLimit} ₽`);
    }
    return rules;
  }, [limits]);

  // схема валидации
  const currentValidationSchema = Yup.object().shape({
    ...validationSchema.fields,
    amount: amountRules,
    ...defaultParameters.reduce((result: ObjectShape, param) => {
      let rules = Yup.string();
      if (param.readOnly) {
        return result;
      }
      if (param.required) {
        rules = rules.required('Обязательное поле');
      }
      if (param.maxLength && param.type !== 'PhoneField' && param.type !== 'CheckBox') {
        rules = rules.max(param.maxLength, 'Некорректные данные');
      }
      if (param.regExp && param.type !== 'PhoneField') {
        rules = rules.transform((originalValue: string) => trimString(originalValue, param.mask?.includes(' '))).test('checkRegExp', 'Некорректные данные', (value: string) => {
          if (!value && !param.required) {
            return true;
          }
          const regExp = new RegExp(param.regExp);
          return regExp.test(value);
        });
      }
      if (param.type === 'PhoneField') {
        rules = rules.matches(phoneRegExp, ERROR_PHONE);
      }
      result[param.name] = rules;
      return result;
    }, {})
  });
  const validateValues = () => {
    let errors: Dictionary<string> = {};
    const values = formikRef?.current?.values;
    try {
      currentValidationSchema.validateSync(values, {
        abortEarly: false
      });
    } catch (e) {
      errors = (e.inner || []).reduce((errorsResult, currentError) => {
        if (!get(errorsResult, currentError.path) && formikRef.current.touched[currentError.path]) {
          set(errorsResult, String(currentError?.path), currentError.message);
        }
        return errorsResult;
      }, {});
    }
    return errors;
  };
  const validateDebounced = debounce(resolve => {
    const errors: Dictionary<string> = validateValues();
    resolve(errors);
  }, 20, {
    leading: false,
    trailing: true
  });
  const validateDebouncedPromise = () => new Promise(resolve => {
    validateDebounced(resolve);
  });
  return <Formik validateOnBlur={false} validateOnMount={false} innerRef={formikRef} validate={validateDebouncedPromise} validateOnChange={false} onSubmit={handleSubmit} initialValues={{
    ...initialValuesWithParams
  }}>
      <PaymentFormFields termsData={termsData} setPayeeData={setPayeeData} isAmountReadonly={isAmountReadonly} setTermsData={setTermsData} serviceInfo={serviceInfo} fields={fields} isLoadingPaymentDo={isPaymentDoLoading || isPaymentSuccess} isCommissionOtherBankForMTS={isCommissionMTSPay} setIsCommissionMTSPay={setIsCommissionMTSPay} />
    </Formik>;
};
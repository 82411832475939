import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { Icon, SkeletonRect, Text, theme, useDidMount } from '@mtsbank/ui-kit';
import { TextField } from '@components/FormFields/TextField';
import { CardInputField } from '@components/FormFields/CardInputField';
import { PasswordField } from '@components/FormFields/PasswordField';
import { Content } from '@components/Content/Content';
import { FieldProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { configPaymentWithoutAuth } from '@components/TspPayee/config/configPaymentWithoutAuth';
import { BindingsState } from '@reducers/processing/types';
import { selectBindings, selectBindingsState } from '@root/selectors/processing';
import { RootState } from '@root/store';
import { fetchBindings } from '@root/reducers/processing/processing';
import { BindingStatus } from '@root/types/bindings';
import { PIPBindingsField } from '@root/components/PIPBindingsField/PIPBindingsField';
import { SberPayContext } from '@root/pages/perevod_v_sber';
import { Binding } from '@open-api/ump/ewallet-profile';
import { PayeeBindingTypesEnum } from '@open-api/ump/catalog-manager';
import { IconWrapper, LabelWrapper, OptionItem, WrapperTermAndCVC } from './styled';
import { getInitialBinding } from '../utils/helpers';
const {
  cardNumber,
  CVCField,
  validityPeriodField
} = configPaymentWithoutAuth.cardDataField;
type Props = {
  handleOnBlur?: (field: Partial<FieldProps>) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  bindingTypes: PayeeBindingTypesEnum[];
  amount: number;
};
export const CardData: FC<Props> = ({
  bindingTypes,
  amount
}) => {
  const sberPayContext = useContext(SberPayContext);
  const [isNewCard, setIsNewCard] = useState(false);
  const {
    values,
    setFieldValue,
    setFieldTouched
  } = useFormikContext<WithoutAuthFormValues>();
  const dispatch = useDispatch();
  const {
    inProgress,
    error
  } = useSelector<RootState, BindingsState>(selectBindingsState);
  const bindings = useSelector(selectBindings);
  const {
    paymentSource
  } = configPaymentWithoutAuth;
  const filterBindings = (binding: Binding) => bindingTypes.includes((binding.bindingType as PayeeBindingTypesEnum));
  useDidMount(() => {
    if (isEmpty(bindings)) {
      dispatch(fetchBindings({
        bindingStatus: BindingStatus.ACTIVE,
        options: sberPayContext?.headers,
        filter: filterBindings
      }));
    }
  });
  const initialBinding = getInitialBinding(bindings);
  useEffect(() => {
    setIsNewCard(!initialBinding);
    if (initialBinding) {
      setFieldValue(paymentSource.name, initialBinding);
    }
  }, [initialBinding, setFieldValue]);
  useEffect(() => {
    setIsNewCard(!values.paymentSource);
  }, [values.paymentSource]);
  const handleChange = useCallback((value: string) => {
    setFieldValue(paymentSource.name, value);
    setFieldTouched(paymentSource.name, true);
  }, [setFieldTouched, setFieldValue]);
  const handleChangeFormCard = () => setIsNewCard(true);
  if (inProgress && isNil(error)) {
    return <Content marginBottom={theme.spacings.xs} marginTop={theme.spacings.xs}>
        <SkeletonRect height={40} />
      </Content>;
  }
  const selectedPaymentSource = values?.paymentSource ? values?.paymentSource : initialBinding;
  return <>
      <Text fontWeight="medium" size="xl">
        {isNewCard ? 'Банковская карта' : 'Способ оплаты'}
      </Text>
      {bindings?.length > 0 && <Content marginBottom={theme.spacings.xs} marginTop={theme.spacings.xs}>
          <PIPBindingsField {...paymentSource} validators={[{
        bindingBalance: {
          toPay: amount
        }
      }]} options={[{
        value: '',
        customOptionHtml: <OptionItem onClick={handleChangeFormCard}>
                    <IconWrapper>
                      <Icon withWrapper icon="baseX24/plus" color={theme.colors.neutral.g400} width={theme.spacings.sm} />
                    </IconWrapper>
                    <LabelWrapper>
                      <Text>Новая карта</Text>
                    </LabelWrapper>
                  </OptionItem>
      }]} size="md" initialValue={isNewCard ? null : selectedPaymentSource} onChange={handleChange} />
        </Content>}

      {isNewCard && <>
          <Content marginBottom={theme.spacings.xs} marginTop={theme.spacings.xs}>
            <Field size="lg" component={CardInputField} {...cardNumber} />
          </Content>
          <WrapperTermAndCVC>
            <Field size="lg" component={TextField} {...validityPeriodField} />
            <Field size="lg" component={PasswordField} {...CVCField} />
          </WrapperTermAndCVC>
        </>}
    </>;
};
import { useRef, useState } from 'react'
import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { ServiceParams, TransferTermsRs } from '@open-api/ump/ewallet'
import { ewalletApi } from '@root/api/ump/ewallet'

interface GetPaymentTermsReqProps {
  zone: 'b' | 'anonymous'
  serviceId?: string
  amount?: number
  currency?: number
  bindingId?: string
  pan?: string
  expiry?: string
  cardholderName?: string
  cvc?: string
  serviceParams?: string
  comment?: string
  masterpassOperationId?: string
  options: any
  onSuccess?: (data: TransferTermsRs) => void
  onError?: (error) => void
}

export const useGetPaymentTerms = () => {
  const [isLoading, setIsLoading] = useState(false)
  const getPaymentTermsRequestAbortController = useRef(new AbortController())
  const { userProfile } = authHelper
  const userId = userProfile?.userId

  const cancelGetPaymentTermsRequest = () => {
    getPaymentTermsRequestAbortController.current.abort()
    getPaymentTermsRequestAbortController.current = new AbortController()
  }

  const getPaymentTerms = async ({
    zone,
    currency,
    amount,
    serviceId,
    bindingId,
    pan,
    expiry,
    cardholderName,
    cvc,
    serviceParams,
    comment,
    masterpassOperationId,
    options,
    onSuccess,
    onError,
  }: GetPaymentTermsReqProps) => {
    getPaymentTermsRequestAbortController.current.abort()
    getPaymentTermsRequestAbortController.current = new AbortController()

    setIsLoading(true)

    try {
      const { data } = await ewalletApi.getPaymentsTermsDo(
        zone,
        serviceId,
        amount,
        currency,
        userId,
        bindingId,
        pan,
        expiry,
        cardholderName,
        cvc,
        serviceParams as ServiceParams,
        comment,
        masterpassOperationId,
        { ...options, signal: getPaymentTermsRequestAbortController.current.signal }
      )

      if (!data.errorMessage) {
        if (onSuccess) onSuccess(data)
      } else if (onError) {
        onError(new Error(data.errorMessage))
      }
    } catch (error) {
      if (onError && error?.code !== 'ERR_CANCELED') onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getPaymentTerms, cancelGetPaymentTermsRequest, isLoading }
}

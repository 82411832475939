import { toNumber } from '@utils/format'
import { Amount } from '@components/TspPayee/config/configPaymentWithoutAuth'
import {
  FIRST_SYMBOLS_CARD_NUMBER,
  COEFFICIENT,
  MTS_BY_PHONE_SERVICE_ID,
  MTS_BY_ORDER_ID_SERVICE_ID,
  SNG_NOT_REQUIRED_AUTH,
} from '@components/TspPayee/utils/constants'
import { binsWithNotCommissionForMTS } from '@components/TspPayee/utils/serviceUtils'
import processStamp from '@components/HistoryOperation/icons/processing.png'
import approveStamp from '@components/HistoryOperation/icons/approve.png'
import declineStamp from '@components/HistoryOperation/icons/decline.png'
import { Status } from '@root/utils/transfer/getStatusName'
import { Binding } from '@open-api/ump/ewallet-profile'
import { NoticeType } from '@root/components/Notice/types'

export const getServiceIcon = (icon: string) =>
  icon.match(/http|https/g) ? icon : `${process.env.NEXT_PUBLIC_STATIC_ICONS_HOST}/prov-icons/${icon}`

export const getComissionOtherBankForMTS = (value: Amount) => {
  const amount = Number(value)
  const amountInPenny = (amount * 100) / COEFFICIENT
  const totalCommission = Math.ceil(amountInPenny) / 100 - amount

  return totalCommission
}

export const isBinWithCommission = (cardNumber: string) => {
  const binCard = cardNumber.replace(/\s/g, '').slice(0, FIRST_SYMBOLS_CARD_NUMBER)
  const isFindBin = Boolean(binsWithNotCommissionForMTS[binCard])

  return isFindBin
}

export const pennyToRubles = (value: number | string) => (value ? Number((toNumber(value) / 100).toFixed(2)) : 0)

export const stampsMapper = {
  [Status.FAIL]: declineStamp,
  [Status.WAIT]: processStamp,
  [Status.SUCCESS]: approveStamp,
}

export const stateMapper = {
  [Status.FAIL]: 'REJECTED',
  [Status.WAIT]: 'PROCESSING',
  [Status.SUCCESS]: 'PROCESSED',
}

export const matchingTransferByStatus = {
  IN_PROGRESS: NoticeType.WAIT,
  EXECUTED: NoticeType.SUCCESS,
  REJECTED: NoticeType.ERROR,
}

export const getInitialBinding = (bindings: Binding[]) => bindings[0]?.bindingId

const MTS_SERVICE_IDS = [MTS_BY_PHONE_SERVICE_ID, MTS_BY_ORDER_ID_SERVICE_ID]

const GK_MTS_BANNER_SERVICES = ['5022', '1593', '1430']

export const isMTS = (serviceId: string) => MTS_SERVICE_IDS.indexOf(serviceId) !== -1

export const isShowBannerGKMts = (serviceId: string) => GK_MTS_BANNER_SERVICES.indexOf(serviceId) !== -1

export const isMtsInternetTv = (serviceId: string) => serviceId === '19171'

export const isMtsPhone = (serviceId: string) => serviceId === '1150'

export const trimString = (value: string, isSpace: boolean) => {
  if (isSpace) {
    return value.replaceAll(/-|_| /g, '')
  }

  return value.replaceAll(/-|_/g, '')
}

export const isSNGRequiredAuthCheck = (serviceId: string) => SNG_NOT_REQUIRED_AUTH.indexOf(serviceId) === -1
